import ReactDOM from 'react-dom';
import { createOperationDescriptor, getRequest, fetchQuery, graphql } from 'relay-runtime';
import { v4 as uuid } from 'uuid';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { isCustomFilter } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import type { actions_issueSearchTotalCountQuery } from '@atlassian/jira-relay/src/__generated__/actions_issueSearchTotalCountQuery.graphql';
import RefetchQuery, {
	type IssueNavigatorIssueSearchRefetchQuery,
	type IssueNavigatorIssueSearchRefetchQuery$variables as IssueNavigatorQueryVariables,
	type JiraIssueSearchOperationScope,
} from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import { getIsConcurrentEnabled } from '@atlassian/jira-react-concurrent/src/utils/get-is-concurrent-enabled.tsx';
import {
	DEFAULT_VIEW_ID,
	MAX_AMOUNT_OF_COLUMNS,
	MAX_ISSUES_PER_PAGE,
	NIN_GLOBAL_OPERATION_SCOPE,
	NIN_GLOBAL_SCHEMA_REFACTOR_OPERATION_SCOPE,
	NIN_PROJECT_OPERATION_SCOPE,
	NIN_PROJECT_SCHEMA_REFACTOR_OPERATION_SCOPE,
} from '../../common/constants.tsx';
import {
	convertFilterIdToIssueNavigatorId,
	isFilterViewId,
	parseIssueNavigatorViewIdOrDefault,
} from '../../common/utils/index.tsx';
import { fetchIssueNavigatorIssueSearchRefetchQueryShadow } from '../issue-search-shadow-request/index.tsx';
import type { Actions, Props, State } from './types.tsx';

/**
 * Private action to refetch issue search results when input arguments have updated.
 */
export const onSearchInputUpdate =
	(): Action<State, Props> =>
	({ dispatch }, { issueSearchInput, filterId, viewId }) => {
		const shouldLoadWithFilterViewId =
			viewId !== 'detail' && issueSearchInput.filterId && isCustomFilter(issueSearchInput.filterId);
		const isCurrentViewIdInvalid = isFilterViewId(viewId) && viewId !== `list_filter_${filterId}`;

		let variables = {};
		if (issueSearchInput.filterId && shouldLoadWithFilterViewId) {
			const issueNavigatorViewId = convertFilterIdToIssueNavigatorId(issueSearchInput.filterId);
			variables = {
				viewId: issueNavigatorViewId,
				fieldSetsInput: {
					viewInput: {
						namespace: 'ISSUE_NAVIGATOR',
						viewId: issueNavigatorViewId,
					},
				},
			};
		} else if (isCurrentViewIdInvalid) {
			variables = {
				viewId: DEFAULT_VIEW_ID,
				fieldSetsInput: {
					viewInput: {
						namespace: 'ISSUE_NAVIGATOR',
						viewId: DEFAULT_VIEW_ID,
					},
				},
			};
		}
		dispatch(actions.onRefetch(variables));
	};

/**
 * Private action to reset the uncapped total issue count.
 */
export const onResetTotalIssueCount =
	(): Action<State, Props> =>
	({ setState }) => {
		setState({
			totalIssueCount: null,
			totalIssueCountIsFetching: false,
			totalIssueCountIsError: false,
		});
	};

export const actions: Actions = {
	onChangeView:
		(viewId) =>
		({ dispatch }, { onSelectedViewMutation, isIssueHierarchySupportEnabled }) => {
			onSelectedViewMutation(viewId);
			dispatch(
				actions.onRefetch({
					viewId,
					fieldSetsInput: {
						viewInput: {
							namespace: 'ISSUE_NAVIGATOR',
							viewId,
						},
					},

					// eslint-disable-next-line jira/ff/no-preconditioning
					...(expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
						isIssueHierarchySupportEnabled && {
							viewConfigInput: { viewInput: { namespace: 'ISSUE_NAVIGATOR', viewId } },
							shouldQueryHasChildren: viewId !== 'detail',
							staticViewInput: null,
						}),
				}),
			);
		},
	onChangeHierarchy:
		(nextIsHierarchyEnabled) =>
		({ dispatch }, { isHierarchyEnabled, groupedByField, isHideDoneEnabled }) => {
			if (nextIsHierarchyEnabled !== isHierarchyEnabled) {
				const staticViewInput = {
					isHierarchyEnabled: nextIsHierarchyEnabled,
					isGroupingEnabled: !!groupedByField,
					...(expVal('jira_list_hide_done_items', 'isEnabled', false) ? { isHideDoneEnabled } : {}),
				};
				dispatch(
					actions.onRefetch({
						staticViewInput,
						viewConfigInput: { staticViewInput },
						shouldQueryHasChildren: Boolean(nextIsHierarchyEnabled),
					}),
				);
			}
		},
	onChangeGroupedByField:
		(nextGroupedByField) =>
		({ dispatch }, { isHierarchyEnabled, isHideDoneEnabled }) => {
			const staticViewInput = {
				isHierarchyEnabled,
				isGroupingEnabled: !!nextGroupedByField,
				...(expVal('jira_list_hide_done_items', 'isEnabled', false) ? { isHideDoneEnabled } : {}),
			};
			dispatch(
				actions.onRefetch({
					groupBy: nextGroupedByField,
					staticViewInput,
					viewConfigInput: { staticViewInput },
				}),
			);
		},
	onChangeHideDone:
		(nextIsHideDoneEnabled) =>
		({ dispatch }, { isHierarchyEnabled, groupedByField, isHideDoneEnabled }) => {
			if (nextIsHideDoneEnabled !== isHideDoneEnabled) {
				const staticViewInput = {
					isHierarchyEnabled,
					isGroupingEnabled: !!groupedByField,
					isHideDoneEnabled: nextIsHideDoneEnabled,
				};
				dispatch(
					actions.onRefetch({
						staticViewInput,
						viewConfigInput: { staticViewInput },
						shouldQueryHasChildren: Boolean(isHierarchyEnabled),
					}),
				);
			}
		},
	onRefetch:
		(variables = {}, options = {}) =>
		(
			{ dispatch, getState, setState },
			{
				cloudId,
				environment,
				issueSearchInput,
				onRefetchStart,
				onRefetchError,
				refetch,
				viewId,
				isHierarchyEnabled,
				projectContext,
				groupedByField,
				isHideDoneEnabled,
			},
		) => {
			let { inFlightRequest } = getState();

			// Some consumers may fire refetch queries before in-flight query
			// has been completed (e.g. while user is toggling columns on and off in
			// the column picker). In these situations, we always want to fetch latest
			// data from the API as column configuration may have changed for the user.
			// To do that, we disable Relay's automatic de-deduping of requests.
			inFlightRequest?.unsubscribe();

			const staticViewInput = {
				isHierarchyEnabled,
				isGroupingEnabled: !!groupedByField,
				isHideDoneEnabled,
			};

			let operationScope: JiraIssueSearchOperationScope;

			if (projectContext) {
				operationScope = isRefactorNinToViewSchemaEnabled()
					? NIN_PROJECT_SCHEMA_REFACTOR_OPERATION_SCOPE
					: NIN_PROJECT_OPERATION_SCOPE;
			} else {
				operationScope = isRefactorNinToViewSchemaEnabled()
					? NIN_GLOBAL_SCHEMA_REFACTOR_OPERATION_SCOPE
					: NIN_GLOBAL_OPERATION_SCOPE;
			}

			const finalVariables: IssueNavigatorQueryVariables = {
				cloudId,
				issueSearchInput,
				first: MAX_ISSUES_PER_PAGE,
				after: null,
				namespace: 'ISSUE_NAVIGATOR',
				viewId,
				options: null,
				fieldSetIds: [],
				shouldQueryFieldSetsById: false,
				shouldQueryHasChildren: isHierarchyEnabled || false,
				fieldSetsInput: {
					viewInput: {
						namespace: 'ISSUE_NAVIGATOR',
						viewId,
					},
				},
				amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
				...(fg('jsc_nin_operation_scope_fe') && {
					scope: { operationScope },
				}),
				filterId: null,
				isRefactorNinToViewSchemaEnabled: isRefactorNinToViewSchemaEnabled(),
				staticViewInput,
				viewConfigInput: { staticViewInput },
				groupBy: groupedByField,
				isPaginating: false,
				projectKeys: projectContext ? [projectContext] : null,
				projectKey: projectContext ?? null,
				...variables,
			};

			inFlightRequest = fetchQuery<IssueNavigatorIssueSearchRefetchQuery>(
				environment,
				RefetchQuery,
				finalVariables,
				{
					fetchPolicy: 'network-only',
					networkCacheConfig: {
						metadata: { META_SLOW_ENDPOINT: true },
					},
				},
			).subscribe({
				complete: () => {
					/**
					 * fetchQuery will NOT retain the data for the query, meaning that it is not guaranteed that the
					 * data will remain saved in the Relay store at any point after the request completes.
					 * We need to explicitly retain the operation to ensure it doesn't get deleted.
					 * See https://relay.dev/docs/api-reference/fetch-query/#behavior
					 */
					const operation = createOperationDescriptor(getRequest(RefetchQuery), finalVariables);
					const { disposables } = getState();
					setState({
						disposables: disposables.concat([environment.retain(operation)]),
					});

					let hasStoreRefetchCompleted = false;

					const storeRefetch = () => {
						refetch(finalVariables, {
							fetchPolicy: 'store-only',
							onComplete: () => {
								/**
								 * Relay will dispose the cached operation after a 5m timeout, in which case it will
								 * refetch the query from the store and retrigger the onComplete callback. We need to
								 * ensure this callback is invoked strictly once to prevent unexpected side effects,
								 * e.g. https://jira.atlassian.com/browse/JRACLOUD-84106.
								 */
								if (hasStoreRefetchCompleted) {
									return;
								}

								hasStoreRefetchCompleted = true;

								/**
								 * A very difficult to debug race condition emerges when setState is called in the same
								 * update cycle as our refetch function. It appears to be related to useSyncExternalStore
								 * (used internally by RSS) internal state becoming out of sync which prevents it from
								 * correctly notifying listeners (i.e. hooks consumers) when an update is made. Scheduling
								 * the update at the end of the event loop is the simplest way of mitigating the bug,
								 * but we should revisit this once React concurrent rendering is enabled.
								 *
								 * Bug this fixes: When clicking "refresh" button multiple times, the shaded loading
								 * state on the table will only appear the first time.
								 */
								queueMicrotask(() => {
									setState({
										isFetching: false,
										isFetchingView: false,
										searchKey: uuid(),
									});
									dispatch(onResetTotalIssueCount());

									options.onComplete?.();
								});
							},
						});
					};

					if (
						getIsConcurrentEnabled() &&
						fg('empanada_nin_concurrent_mode_fixes_concurrent_reqd')
					) {
						/**
						 * Delaying the store refetch until the next tick in order to avoid a race condition that occurs
						 * when react concurrent mode is enabled. In the future (once concurrent mode rollout is
						 * complete), we should replace this suspenseless refetch pattern with transitions.
						 *
						 * Bug this fixes: Features that perform state updates shortly after the refetch (like toggling
						 * hierarchy or grouping) can result in a flicker between old results an new results.
						 */
						setTimeout(storeRefetch, 0);
					} else {
						/**
						 * We need to batch these updates as without it, Relay will cause multiple renders and invoke the
						 * onComplete callback multiple times. This causes our success/fail analytics events to become
						 * unreliable.
						 * We introduced a similar fix for fetchQuery (see src/packages/platform/graphql/relay-scheduler/src/index.js)
						 * and in React 18 will be able to remove this in favour of the startTransition/useTransition APIs.
						 */
						ReactDOM.unstable_batchedUpdates(storeRefetch);
					}

					if (projectContext && viewId !== 'detail') {
						if (fg('jira_list_hierarchy_shadow_requests')) {
							fetchIssueNavigatorIssueSearchRefetchQueryShadow(
								{
									...finalVariables,
									staticViewInput: {
										...(expVal('jira_list_hide_done_items', 'isEnabled', false)
											? finalVariables.staticViewInput
											: {}),
										isHierarchyEnabled: true,
									},
									viewConfigInput: {
										staticViewInput: {
											...(finalVariables.viewConfigInput &&
											expVal('jira_list_hide_done_items', 'isEnabled', false)
												? finalVariables.viewConfigInput.staticViewInput
												: {}),
											isHierarchyEnabled: true,
										},
									},
									shouldQueryHasChildren: true,
								},
								false,
							);
						} else if (fg('jira_list_group_shadow_requests')) {
							const groupByFields = fg('jira_list_main_shadow_query_group_overrides')
								? [
										'status',
										'assignee',
										'priority',
										'com.atlassian.jira.plugin.system.customfieldtypes:float',
										'com.pyxis.greenhopper.jira:jsw-story-points',
										'com.pyxis.greenhopper.jira:gh-sprint',
										'com.atlassian.jira.plugin.system.customfieldtypes:jwm-category',
									]
								: ['status', 'assignee', 'priority'];

							fetchIssueNavigatorIssueSearchRefetchQueryShadow(
								{
									...finalVariables,
									staticViewInput: {
										...(expVal('jira_list_hide_done_items', 'isEnabled', false)
											? finalVariables.staticViewInput
											: {}),
										isHierarchyEnabled: false,
										isGroupingEnabled: true,
									},
									viewConfigInput: {
										staticViewInput: {
											...(finalVariables.viewConfigInput &&
											expVal('jira_list_hide_done_items', 'isEnabled', false)
												? finalVariables.viewConfigInput.staticViewInput
												: {}),
											isHierarchyEnabled: false,
											isGroupingEnabled: true,
										},
									},
									// Randomly select a groupBy field to test the shadow request
									groupBy: groupByFields[Math.floor(Math.random() * groupByFields.length)],
								},
								true,
							);
						}
					}
				},
				error: (error: Error) => {
					setState({
						isFetching: false,
						isFetchingView: false,
						isNetworkError: true,
					});
					options.onError?.();
					onRefetchError?.(error, parseIssueNavigatorViewIdOrDefault(finalVariables.viewId));
				},
				unsubscribe: () => {
					options.onUnsubscribe?.();
				},
			});

			onRefetchStart?.();
			setState({
				inFlightRequest,
				isFetching: true,
				isFetchingView: viewId !== finalVariables.viewId,
				isNetworkError: false,
			});
		},
	onRefresh:
		() =>
		({ setState, dispatch }) => {
			setState({ isRefreshing: true });
			const onFinish = () => {
				setState({ isRefreshing: false });
			};
			dispatch(
				actions.onRefetch(
					{},
					{
						onComplete: onFinish,
						onError: onFinish,
						onUnsubscribe: onFinish,
					},
				),
			);
		},
	onFetchTotalIssueCount:
		() =>
		(
			{ setState },
			{
				environment,
				cloudId,
				issueSearchInput,
				isHierarchyEnabled,
				isHideDoneEnabled,
				isIssueHierarchySupportEnabled,
			},
		) => {
			setState({
				totalIssueCount: null,
				totalIssueCountIsFetching: true,
				totalIssueCountIsError: false,
			});
			const viewConfigInput =
				// eslint-disable-next-line jira/ff/no-preconditioning
				(expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
					isIssueHierarchySupportEnabled) ||
				(isHideDoneEnabled && expVal('jira_list_hide_done_items', 'isEnabled', false))
					? { staticViewInput: { isHierarchyEnabled, isHideDoneEnabled } }
					: null;

			fetchQuery<actions_issueSearchTotalCountQuery>(
				environment,
				graphql`
					query actions_issueSearchTotalCountQuery(
						$cloudId: ID!
						$issueSearchInput: JiraIssueSearchInput!
						$viewConfigInput: JiraIssueSearchViewConfigInput
					) {
						jira {
							issueSearchTotalCount(
								cloudId: $cloudId
								issueSearchInput: $issueSearchInput
								viewConfigInput: $viewConfigInput
							) @optIn(to: ["JiraTotalIssueCount"])
						}
					}
				`,
				{ cloudId, issueSearchInput, viewConfigInput },
				{
					fetchPolicy: 'network-only',
					networkCacheConfig: {
						metadata: { META_SLOW_ENDPOINT: true },
					},
				},
			).subscribe({
				next: (data) => {
					const totalIssueCount = data?.jira?.issueSearchTotalCount ?? null;
					const totalIssueCountIsError = totalIssueCount === null;

					setState({
						totalIssueCount,
						totalIssueCountIsFetching: false,
						totalIssueCountIsError,
					});
				},
				error: () => {
					setState({
						totalIssueCount: null,
						totalIssueCountIsFetching: false,
						totalIssueCountIsError: true,
					});
				},
			});
		},
};
