import React, {
	type ComponentType,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type ComponentPropsWithoutRef,
	type PropsWithChildren,
	Fragment,
} from 'react';

import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { IssueFieldStatisticsEntrypointProvider as IssueFieldStatisticsEntrypointProviderBase } from '@atlassian/jira-issue-navigator-action-issue-field-statistics/src/controllers/entrypoint-context/index.tsx';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import { asyncBulkOpsCoreEntryPoint } from '@atlassian/jira-bulk-operations-async-core/entrypoint.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { useGlobalScopeOptInFlag } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/enable-nin-changeboarding/index.tsx';
import { JQLDebuggerRenderSlot } from '@atlassian/jira-jql-debugger/src/ui/async.tsx';
import { IssueTableSelectedIssuesContainer } from '@atlassian/jira-native-issue-table/src/controllers/selected-issues/index.tsx';
import type { IssueTableSelectedIssuesContainerProps } from '@atlassian/jira-native-issue-table/src/controllers/selected-issues/types.tsx';
import { HierarchyOnboardingProvider as HierarchyOnboardingProviderNew } from '@atlassian/jira-issue-table-hierarchy/src/ui/hierarchy-onboarding/index.tsx';
import { HierarchyOnboardingProvider as HierarchyOnboardingProviderOld } from '@atlassian/jira-native-issue-table/src/ui/hierarchy-onboarding/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { jqlBuilder_issueNavigator_JQLBuilderWrapper_filter$key as JqlBuilderFilterFragmentType } from '@atlassian/jira-relay/src/__generated__/jqlBuilder_issueNavigator_JQLBuilderWrapper_filter.graphql';
import type { main_issueNavigator_Header_filter$key as HeaderFilterFragmentType } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Header_filter.graphql';
import type { main_issueNavigator_IssueNavigatorUI_groupResults$key as GroupResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_groupResults.graphql';
import type { main_issueNavigator_IssueNavigatorUI_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_issueResults.graphql';
import type { main_issueNavigator_IssueNavigatorUI_jiraView$key as JiraViewFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_jiraView.graphql';
import type { main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey$key as JqlBuilderWithAiKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey.graphql';
import type { main_issueNavigator_IssueNavigatorUI_project$key as ProjectFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_project.graphql';
import type { main_issueNavigator_IssueNavigatorUI_userPreferences$key as UserPreferencesFragmentKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_userPreferences.graphql';
import type { main_issueNavigator_IssueNavigatorUI_viewResult$key as ViewResultFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigatorUI_viewResult.graphql';
import type { main_issueNavigator_ListView_filter$key as ListViewFilterFragmentType } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_filter.graphql';
import type { topBar_issueNavigator_filter$key as TopBarFilterFragment } from '@atlassian/jira-relay/src/__generated__/topBar_issueNavigator_filter.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import { SidebarLayout as BaseSidebarLayout } from '@atlassian/jira-platform-sidebar/src/ui/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import {
	PACKAGE_NAME,
	isJiraIssueSearchViewMetadata,
	GROUPED_LIST_EXPERIENCE_ID,
} from '../common/constants.tsx';
import type {
	CustomHeaderProps,
	OverridableIssueTableProps,
	IssueNavigatorViewId,
} from '../common/types.tsx';
import { NoFilter } from '../common/ui/no-filter/index.tsx';
import { markOnce, marks } from '../common/utils/performance-analytics.tsx';
import {
	IssueSearchFail,
	IssueSearchSuccess,
} from '../controllers/issue-search-analytics/index.tsx';
import { useIsFullPageIssueAppMode } from '../controllers/selected-issue/facade.tsx';
import { useSelectedViewState } from '../controllers/selected-view-state/index.tsx';
import { useActiveJql, useResolvedJql } from '../services/active-jql/index.tsx';
import { useFilterQuery } from '../services/filter-query/index.tsx';
import { useIssueSearchQuery } from '../services/issue-search-query/index.tsx';
import { useSearchKey } from '../services/issue-search/selectors.tsx';
import { useFlatListIssuesActions } from '../controllers/flat-list-issues/index.tsx';
import { InlineFieldConfigProviderComponent } from '../common/ui/inline-field-config/context/index.tsx';
import { BulkOperationsWrapper } from './bulk-operations-wrapper/index.tsx';
import FullPageIssueApp from './full-page-issue-app/main.tsx';
import Header from './header/main.tsx';
import IssueResults from './issue-results/main.tsx';
import JQLBuilder, { type OverridableJqlBuilderProps } from './jql-builder/index.tsx';
import messages from './messages.tsx';
import RegisterDotShortcut from './register-dot-shortcut/index.tsx';
import IssueNavigatorSpotlight from './spotlight/index.tsx';
import TopBar from './top-bar/index.tsx';
import { SidebarCloserController } from './sidebar/sidebar-closer/index.tsx';
import { SidebarExperienceRegistryWithErrorBoundary } from './sidebar/experience-registry/index.tsx';

const SidebarLayout = componentWithFG(
	'jira_nin_sidebar_layout',
	({ children }: PropsWithChildren<{}>) => (
		<BaseSidebarLayout
			defaultRatio={0.3}
			minWidth={360}
			maxRatio={0.75}
			teamName="empanada"
			content={<SidebarExperienceRegistryWithErrorBoundary />}
		>
			{children}
			<SidebarCloserController />
		</BaseSidebarLayout>
	),
	({ children }: PropsWithChildren<{}>) => <>{children}</>,
);

const staticEntrypointParams = {
	cloudId: undefined,
	projectKey: undefined,
};
const staticUseEntryPointFGOff = {
	entryPointActions: {
		load: noop,
	},
	entryPointReferenceSubject: undefined,
};

const IssueFieldStatisticsEntrypointProvider = (
	props: ComponentPropsWithoutRef<typeof IssueFieldStatisticsEntrypointProviderBase>,
) => {
	if (expVal('helios_list_view_statistics_nin_migration', 'isEnabled', true)) {
		return <IssueFieldStatisticsEntrypointProviderBase {...props} />;
	}
	return <>{props.children}</>;
};

const ConditionalIssueTableSelectedIssuesContainer = ({
	children,
	onIssueSelected,
}: IssueTableSelectedIssuesContainerProps) => {
	if (!expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)) {
		return children;
	}
	return (
		<IssueTableSelectedIssuesContainer onIssueSelected={onIssueSelected}>
			{children}
		</IssueTableSelectedIssuesContainer>
	);
};

type Props = {
	issueResults: IssueResultsFragment | null;
	groupResults: GroupResultsFragment | null;
	CustomHeader?: ComponentType<CustomHeaderProps>;
	ActionMenu?: ComponentType<CustomHeaderProps>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderSkeletonImage: ComponentType<Record<any, any>>;
	defaultJql: string;
	filter:
		| (HeaderFilterFragmentType &
				TopBarFilterFragment &
				JqlBuilderFilterFragmentType &
				ListViewFilterFragmentType)
		| null;
	viewResult: ViewResultFragment | null;
	project: ProjectFragment | null;
	userPreferences: UserPreferencesFragmentKey | null;
	onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
	onChangeFilter?: (filterId?: string) => void;
	onRefinement?: () => void;
	onChangeIssue: (
		issueKey: IssueKey,
		isSelectedByUserInteraction: boolean,
		viewActivity?: boolean,
	) => void;
	onChangeColumnConfiguration?: () => void;
	onChangeJql?: (jql: string, clearFilter?: boolean) => void;
	// @deprecated use onIssueFlatListUpdated instead. Remove when cleaning up bulk_operations_in_nin_experiment
	onIssueFlatListUpdatedOld?: (newFlatList: string[]) => void;
	onIssueFlatListUpdated?: (newFlatKeyList: string[], newFlatIdList: string[]) => void;
	jqlBuilderProps?: OverridableJqlBuilderProps;
	jqlBuilderWithAiKey: JqlBuilderWithAiKey | null;
	issueTableProps?: OverridableIssueTableProps;
	isIssueHierarchySupportEnabled?: boolean;
	jiraView: JiraViewFragment | null;
	initialQueryAsJql?: string;
};

export const IssueNavigatorUI = ({
	CustomHeader,
	ActionMenu,
	HeaderSkeletonImage,
	filter,
	onPageDataLoad,
	onChangeColumnConfiguration,
	onChangeJql,
	onChangeIssue,
	onChangeFilter,
	defaultJql,
	onRefinement,
	onIssueFlatListUpdatedOld,
	onIssueFlatListUpdated,
	issueResults,
	groupResults,
	viewResult,
	project,
	userPreferences,
	jqlBuilderProps,
	jqlBuilderWithAiKey,
	issueTableProps,
	isIssueHierarchySupportEnabled,
	jiraView,
	initialQueryAsJql,
}: Props) => {
	markOnce(marks.ISSUE_NAVIGATOR_UI_START);
	useLayoutEffect(() => {
		markOnce(marks.ISSUE_NAVIGATOR_UI_END);
	}, []);
	const { formatMessage } = useIntl();
	const [caughtIssueSearchError, setCaughtIssueSearchError] = useState<Error>();

	const { filterId } = useActiveJql();
	const resolvedJql = useResolvedJql();
	const { filterQueryIsFetching } = useFilterQuery();

	const handleReset = useCallback(
		(isResetToFilter = false) => {
			if (isResetToFilter) {
				onChangeFilter && onChangeFilter();
			} else {
				onChangeJql && onChangeJql(defaultJql, true);
			}
		},
		[onChangeFilter, onChangeJql, defaultJql],
	);
	const onChange = useCallback(
		(jql: string) => {
			onRefinement && onRefinement();
			onChangeJql &&
				onChangeJql(jql, !filterQueryIsFetching && filter === null && isFilterId(filterId));
		},
		[filter, filterId, filterQueryIsFetching, onChangeJql, onRefinement],
	);

	let searchKey;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		searchKey = useSearchKey();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		searchKey = useIssueSearchQuery().searchKey;
	}
	const previousData = usePrevious(searchKey);
	const hasSearchChanged = Boolean(searchKey && searchKey !== previousData);

	const [{ view: selectedView }] = useSelectedViewState();

	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_issueResults on JiraIssueConnection
			@argumentDefinitions(
				bulkOpsInNinEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider"
				}
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				...topBar_issueNavigator_issueResults
				...main_issueNavigator_Header_issueResults
				...jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults
				...main_issueNavigator_IssueResults_issueResults
					@arguments(
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
					)
				...main_issueNavigator_FullPageIssueApp_issueResults
				issueSearchError {
					__typename
				}
				totalIssueSearchResultCount
				isCappingIssueSearchResult
				...bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore @include(if: $bulkOpsInNinEnabled)
			}
		`,
		issueResults,
	);

	const groupResultsData = useFragment<GroupResultsFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_groupResults on JiraSpreadsheetGroupConnection
			@argumentDefinitions(projectKeys: { type: "[String!]" }, projectKey: { type: "String" }) {
				...main_issueNavigator_IssueResults_groupResults
					@arguments(projectKeys: $projectKeys, projectKey: $projectKey)
				...jqlBuilder_issueNavigator_JQLBuilderWrapper_groupResults
				errors {
					extensions {
						statusCode
					}
					message
				}
			}
		`,
		groupResults,
	);

	const viewResultData = useFragment<ViewResultFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_viewResult on JiraIssueSearchViewResult {
				__typename
				...main_issueNavigator_IssueResults_viewResult
				... on JiraIssueSearchView {
					...main_issueNavigator_FullPageIssueApp_view
					...main_issueNavigator_Header_viewResult
					...topBar_issueNavigator_viewResult
				}
				... on QueryError {
					message
					extensions {
						statusCode
					}
				}
			}
		`,
		viewResult,
	);

	const jiraViewData = useFragment(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_jiraView on JiraView {
				__typename
				error {
					extensions {
						statusCode
					}
					message
				}
				...main_issueNavigator_Header_jiraViewResult
				...main_issueNavigator_IssueResults_jiraView
				...topBar_issueNavigator_jiraViewResult
				... on JiraIssueSearchViewMetadata {
					...main_issueNavigator_FullPageIssueApp_view
				}
				... on JiraSpreadsheetView {
					viewSettings(
						groupBy: $groupBy
						staticViewInput: $staticViewInput
						issueSearchInput: $issueSearchInput
					) {
						isGroupingEnabled
						canEnableGrouping
					}
				}
			}
		`,
		jiraView,
	);

	const viewResultError = useMemo(() => {
		if (jiraViewData && isRefactorNinToViewSchemaEnabled()) {
			const message = jiraViewData.error?.message ?? '';
			const statusCode = jiraViewData.error?.extensions?.[0].statusCode;

			if (typeof statusCode !== 'number') {
				return;
			}

			return new FetchError(statusCode, message);
		}

		return viewResultData?.__typename === 'QueryError' &&
			viewResultData?.extensions?.[0]?.statusCode !== undefined &&
			viewResultData?.extensions?.[0]?.statusCode !== null
			? new FetchError(viewResultData?.extensions?.[0]?.statusCode, viewResultData?.message ?? '')
			: undefined;
	}, [
		jiraViewData,
		viewResultData?.__typename,
		viewResultData?.extensions,
		viewResultData?.message,
	]);

	const userPreferencesData = useFragment<UserPreferencesFragmentKey>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_userPreferences on JiraUserPreferences {
				...spotlight_issueNavigator_IssueNavigatorSpotlight
			}
		`,
		userPreferences,
	);

	const jqlBuilderWithAiData = useFragment<JqlBuilderWithAiKey>(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey on JiraQuery {
				...jqlBuilder_issueNavigator_JQLBuilderWrapper_jqlBuilderWithAiKey
			}
		`,
		jqlBuilderWithAiKey,
	);

	const projectData = useFragment(
		graphql`
			fragment main_issueNavigator_IssueNavigatorUI_project on JiraProject {
				...main_issueNavigator_IssueResults_project
				projectType
			}
		`,
		project,
	);

	const isGrouping = useMemo(
		() =>
			issueTableProps?.isGroupingSupported &&
			jiraViewData?.viewSettings?.isGroupingEnabled &&
			jiraViewData?.viewSettings?.canEnableGrouping,
		[
			issueTableProps?.isGroupingSupported,
			jiraViewData?.viewSettings?.canEnableGrouping,
			jiraViewData?.viewSettings?.isGroupingEnabled,
		],
	);

	const shouldAnnounceInfo = useMemo(
		() =>
			hasSearchChanged &&
			(isGrouping
				? groupResultsData !== null
				: issueResultsData !== null &&
					typeof issueResultsData.totalIssueSearchResultCount === 'number'),
		[hasSearchChanged, isGrouping, groupResultsData, issueResultsData],
	);

	const [isAiEnabled, setIsAiEnabled] = useState(false);
	const onToggleAi = useCallback((isEnabled: boolean) => {
		setIsAiEnabled(isEnabled);
	}, []);

	let hasServerError = issueResultsData?.issueSearchError?.__typename === 'JiraServerError';

	if (isRefactorNinToViewSchemaEnabled()) {
		hasServerError =
			hasServerError ||
			(groupResultsData?.errors ?? []).some(
				({ extensions }) =>
					!extensions?.some(
						({ statusCode }) =>
							typeof statusCode === 'number' && statusCode >= 400 && statusCode < 500,
					),
			);
	}

	const hasViewNotFoundError = isRefactorNinToViewSchemaEnabled()
		? !!viewResultError && viewResultError.statusCode === 404
		: viewResultData?.__typename === 'QueryError' &&
			viewResultData?.extensions?.[0]?.statusCode === 404;

	const noFilterReason: string | undefined = useMemo(() => {
		if (!filterQueryIsFetching && filter === null && isFilterId(filterId)) {
			return 'Filter query mismatch';
		}
		if (hasViewNotFoundError) {
			return 'Filter not found in view result';
		}
		return undefined;
	}, [filter, filterId, filterQueryIsFetching, hasViewNotFoundError]);

	const IssueNavigatorTopBar = useMemo(() => {
		const jqlBuilder = (
			<JQLBuilder
				onChangeJql={onChange}
				defaultJql={defaultJql}
				filter={filter}
				onReset={handleReset}
				onFilterSave={onChangeFilter}
				groupResults={groupResultsData}
				issueResults={issueResultsData}
				onToggleAi={onToggleAi}
				jqlBuilderWithAiKey={jqlBuilderWithAiData}
				jqlBuilderProps={jqlBuilderProps}
				initialQueryAsJql={initialQueryAsJql}
			/>
		);

		return (
			<>
				{ActionMenu && (
					<TopBar
						ActionMenu={ActionMenu}
						isGroupingSupported={issueTableProps?.isGroupingSupported}
						filter={filter}
						issueResults={issueResultsData}
						HeaderSkeletonImage={HeaderSkeletonImage}
						jqlBuilder={jqlBuilder}
						viewResult={
							viewResultData?.__typename === 'JiraIssueSearchView' ? viewResultData : null
						}
						jiraViewResult={jiraViewData}
					/>
				)}
				{CustomHeader && (
					<>
						<Header
							CustomHeader={CustomHeader}
							filter={filter}
							issueResults={issueResultsData}
							HeaderSkeletonImage={HeaderSkeletonImage}
							viewResult={
								viewResultData?.__typename === 'JiraIssueSearchView' ? viewResultData : null
							}
							jiraViewResult={jiraViewData}
							isGroupingSupported={issueTableProps?.isGroupingSupported}
						/>
						{jqlBuilder}
					</>
				)}
			</>
		);
	}, [
		onChange,
		defaultJql,
		filter,
		handleReset,
		onChangeFilter,
		groupResultsData,
		issueResultsData,
		onToggleAi,
		jqlBuilderWithAiData,
		jqlBuilderProps,
		initialQueryAsJql,
		ActionMenu,
		issueTableProps?.isGroupingSupported,
		HeaderSkeletonImage,
		viewResultData,
		jiraViewData,
		CustomHeader,
	]);

	const { entryPointActions, entryPointReferenceSubject } = expVal(
		'bulk_operations_in_nin_experiment',
		'isEnabled',
		false,
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useEntryPoint(asyncBulkOpsCoreEntryPoint, staticEntrypointParams)
		: staticUseEntryPointFGOff;

	let setFlatListIssueIds = noop;
	if (
		// eslint-disable-next-line jira/ff/no-preconditioning
		expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
		fg('jsc_m2_hierarchy_fe_changes') &&
		expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)
	) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ setFlatListIssueIds } = useFlatListIssuesActions());
	}

	const handleIssueFlatListUpdated = useCallback(
		(newFlatKeyList: string[], newFlatIdList: string[]) => {
			setFlatListIssueIds(newFlatIdList);
			if (onIssueFlatListUpdated) {
				onIssueFlatListUpdated(newFlatKeyList, newFlatIdList);
			}
		},
		[setFlatListIssueIds, onIssueFlatListUpdated],
	);

	let IssueNavigatorContent = useMemo(() => {
		const getAnnouncerMessage = () => {
			if (isGrouping) {
				return formatMessage(messages.screenReaderGroupResultInfo);
			}

			let message;
			if (issueResultsData?.isCappingIssueSearchResult) {
				message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.screenReaderSearchResultMoreInfoIssueTermRefresh
					: messages.screenReaderSearchResultMoreInfo;
			} else {
				message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.screenReaderSearchResultInfoIssueTermRefresh
					: messages.screenReaderSearchResultInfo;
			}

			return formatMessage(message, {
				total: issueResultsData?.totalIssueSearchResultCount,
			});
		};

		const HierarchyOnboardingProvider = fg('jira_list_hierarchy_extraction')
			? HierarchyOnboardingProviderNew
			: HierarchyOnboardingProviderOld;

		const HierarchyOnboardingProviderComponent =
			// eslint-disable-next-line jira/ff/no-preconditioning
			expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
			fg('jsc_m2_hierarchy_fe_changes')
				? HierarchyOnboardingProvider
				: Fragment;

		return (
			<ConditionalIssueTableSelectedIssuesContainer onIssueSelected={entryPointActions.load}>
				<IssueFieldStatisticsEntrypointProvider jql={resolvedJql} view={selectedView}>
					<SidebarLayout>
						<BulkOperationsWrapper
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore - error TS2322: - type mismatch
							bulkOpsCoreEntryPointReference={entryPointReferenceSubject}
							fragmentRef={issueResultsData}
						>
							<HierarchyOnboardingProviderComponent>
								<InlineFieldConfigProviderComponent>
									<IssueNavigatorContentContainer
										selectedView={selectedView}
										data-vc={`issue-navigator-content${__SERVER__ ? '-ssr' : ''}`}
										{...(__SERVER__ &&
											fg('add_ssr_placeholder_replacements_to_nin_and_nav') && {
												'data-ssr-placeholder': 'issue-navigator-content-placeholder',
											})}
										{...(!__SERVER__ &&
											fg('add_ssr_placeholder_replacements_to_nin_and_nav') && {
												'data-ssr-placeholder-replace': 'issue-navigator-content-placeholder',
											})}
									>
										{IssueNavigatorTopBar}

										{expVal('gravityai-1783_jql_debugger', 'jql_debugger_enabled', false) ? (
											<JQLDebuggerRenderSlot />
										) : null}

										{(!filterQueryIsFetching && filter === null && isFilterId(filterId)) ||
										hasViewNotFoundError ? (
											<NoFilter reason={noFilterReason} />
										) : (
											<>
												<AnnouncerV2
													message={getAnnouncerMessage()}
													shouldAnnounce={shouldAnnounceInfo}
													testId="issue-navigator.ui.search-announcer"
												/>
												<IssueResults
													hasServerError={hasServerError}
													onChangeColumnConfiguration={onChangeColumnConfiguration}
													onChangeJql={onChangeJql}
													onCaughtIssueSearchError={setCaughtIssueSearchError}
													issueResults={issueResultsData}
													viewResult={viewResultData}
													jiraView={jiraViewData}
													project={projectData}
													groupResults={groupResultsData}
													issueTableProps={issueTableProps}
													isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
													onIssueFlatListUpdatedOld={onIssueFlatListUpdatedOld}
													onIssueFlatListUpdated={handleIssueFlatListUpdated}
													filter={filter}
												/>
											</>
										)}
										<IssueNavigatorSpotlight
											hasIssueResults={!!issueResultsData?.totalIssueSearchResultCount}
											userPreferences={userPreferencesData}
											isAiEnabled={isAiEnabled}
										/>
									</IssueNavigatorContentContainer>
								</InlineFieldConfigProviderComponent>
							</HierarchyOnboardingProviderComponent>
						</BulkOperationsWrapper>
					</SidebarLayout>
				</IssueFieldStatisticsEntrypointProvider>
			</ConditionalIssueTableSelectedIssuesContainer>
		);
	}, [
		entryPointActions.load,
		resolvedJql,
		selectedView,
		entryPointReferenceSubject,
		issueResultsData,
		IssueNavigatorTopBar,
		filterQueryIsFetching,
		filter,
		filterId,
		hasViewNotFoundError,
		noFilterReason,
		shouldAnnounceInfo,
		hasServerError,
		onChangeColumnConfiguration,
		onChangeJql,
		viewResultData,
		jiraViewData,
		projectData,
		groupResultsData,
		issueTableProps,
		isIssueHierarchySupportEnabled,
		onIssueFlatListUpdatedOld,
		handleIssueFlatListUpdated,
		userPreferencesData,
		isAiEnabled,
		isGrouping,
		formatMessage,
	]);

	const isFullPageIssueAppMode = useIsFullPageIssueAppMode();

	if (isFullPageIssueAppMode) {
		const view = jiraViewData && isJiraIssueSearchViewMetadata(jiraViewData) ? jiraViewData : null;
		const oldView = viewResultData?.__typename === 'JiraIssueSearchView' ? viewResultData : null;

		IssueNavigatorContent = (
			<FullPageIssueApp
				onChangeIssue={onChangeIssue}
				issueResults={issueResultsData}
				view={isRefactorNinToViewSchemaEnabled() ? view : oldView}
				{...(fg('add-attribute-required-for-colorful-labels-expe')
					? { projectType: projectData?.projectType }
					: {})}
			/>
		);
	}

	useGlobalScopeOptInFlag();

	const hasSearchError = hasServerError || !!caughtIssueSearchError;
	const hasViewResultData = !!viewResultData;
	const hasViewResultError =
		viewResultData?.__typename === 'QueryError' &&
		viewResultData?.extensions?.[0]?.statusCode !== 404;

	let hasSearchResults = !!issueResultsData;
	let isFailedIssueSearch =
		!hasSearchResults || hasSearchError || !hasViewResultData || hasViewResultError;

	if (isRefactorNinToViewSchemaEnabled()) {
		hasSearchResults = !!issueResultsData || !!groupResultsData;
		isFailedIssueSearch =
			!hasSearchResults ||
			hasSearchError ||
			!jiraViewData ||
			(!!viewResultError && viewResultError.statusCode !== 404);
	}

	return useMemo(
		() => (
			<>
				{IssueNavigatorContent}
				<RegisterDotShortcut />
				<FireScreenAnalytics />
				{isFailedIssueSearch ? (
					<IssueSearchFail
						location={`${PACKAGE_NAME}.ui`}
						error={
							caughtIssueSearchError ||
							viewResultError ||
							new Error('There was an error searching for issues')
						}
						onPageDataLoad={onPageDataLoad}
						attributes={{
							isAiEnabled,
							...(isGrouping ? { experienceId: GROUPED_LIST_EXPERIENCE_ID } : {}),
						}}
					/>
				) : (
					<IssueSearchSuccess
						onPageDataLoad={onPageDataLoad}
						attributes={{
							isAiEnabled,
							...(isGrouping ? { experienceId: GROUPED_LIST_EXPERIENCE_ID } : {}),
						}}
					/>
				)}
			</>
		),
		[
			IssueNavigatorContent,
			isFailedIssueSearch,
			caughtIssueSearchError,
			viewResultError,
			onPageDataLoad,
			isAiEnabled,
			isGrouping,
		],
	);
};

// re-render regression detection
IssueNavigatorUI.whyDidYouRender = true;

export default memo<
	JSX.LibraryManagedAttributes<typeof IssueNavigatorUI, ComponentProps<typeof IssueNavigatorUI>>
>(IssueNavigatorUI);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueNavigatorContentContainer = styled.div<{ selectedView: IssueNavigatorViewId }>(
	{
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		marginTop: 0,
		marginRight: token('space.400'),
		marginBottom: 0,
		marginLeft: token('space.400'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ selectedView }) =>
		selectedView !== 'detail' && {
			paddingBottom: token('space.250'),
			boxSizing: 'border-box',
		},
);
